import React, { useEffect} from 'react';
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/Seo';
import Grid from '@mui/material/Grid';
import CtaButton from '@mui-components/ctaButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from 'gatsby';
import VideoTranscriptLink from '../../components/structure/VideoTranscriptLink';
import { Helmet } from 'react-helmet';

const OnDemandVideo = ({ location }) => {

	useEffect(() => {
		//fix for weird firefox issue
		setTimeout(function () {
			window.scrollTo(0, 0);
		}, 100);
	}, [location]);

	if (location.state === null || location.state === undefined) {
		return null;
	} else {
		function getClass() {
			var classBase = 'wistia_embed wistia_async_';
			var urlID = location.state.fromFeed.videoId;
			var foam = '  videoFoam=true';

			return classBase + urlID + foam;
		}

		function getVideoUrl() {
			var urlBase = 'https://fast.wistia.com/embed/medias/';
			var videoUrlID = location.state.fromFeed.videoId;
			var swatch = '/swatch';

			return urlBase + videoUrlID + swatch;
		}

		return (
			<Layout pageType='ih-branded'>
				<Helmet>
					<script
						src='https://fast.wistia.com/embed/medias/7f5c9r1sy2.jsonp'
						async></script>
					<script
						src='https://fast.wistia.com/assets/external/E-v1.js'
						async></script>
				</Helmet>
				<Seo
					title={'Idiopathic Hypersomnia Treatment Webinars | '+location.state.fromFeed.title}
					description={location.state.fromFeed.desc}
				/>

				<div className='branded-onDemandVideo-ih'>
					<div className='heroBackground'></div>
					<div className='container'>
						<section className='heroSection'>
							<Link
								to={`/xywav-idiopathic-hypersomnia`}
								state={{ onDemandTabSelected: true }}
								className='goBackLink'>
								<ArrowBackIosNewIcon className='backArrow' />
								Back to Webinars On-Demand Video Library
							</Link>
							<div className='video'>
								<div className='wistia_responsive_padding'>
									<div className='wistia_responsive_wrapper'>
										<div className={getClass()}>
											<div className='wistia_swatch'>
												<img
													className='wistia_swatch_img'
													src={getVideoUrl()}
													alt=''
													aria-hidden='true'
													onload='this.parentNode.style.opacity=1;'
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <VideoTranscriptLink pageType='narcolepsy-branded'>
								{location.state.fromFeed.videoTranscript.map(
									(videoTranscriptItem, index) => (
										<p>{videoTranscriptItem.paragraph}</p>
									)
								)}
							</VideoTranscriptLink> */}
						</section>
						<section className='copySection'>
							<p className='header font-cocon'>
								{location.state.fromFeed.title}
							</p>
							{/* <p className='credits'>{location.state.fromFeed.speaker}</p> */}
							<div className='divider'>
								<img src='/images/gradient-divider-ih.svg' alt='' />
							</div>
							<p className='bottomCopy'>{location.state.fromFeed.desc}</p>
						</section>
						<section className='learnMoreSection'>
							{location.state.fromFeed.videoResource.map(
								(resourceItem, index) => (
									<div className='learnMoreItem'>
										<Grid
											container
											direction={'row'}
											justifyContent={'center'}>
											<Grid item xs={12} md={0.5}></Grid>
											<Grid item xs={12} md={6.5}>
												<div className='copyLeft'>
													<p className='learnMoreHeader'>
														{resourceItem.resourceTitle}
													</p>
													<p className='learnMoreCopy'>
														{resourceItem.resourceDescription}
													</p>
												</div>
											</Grid>
											<Grid item xs={12} md={0.5}></Grid>
											<Grid item xs={12} md={4.5}>
												<div>
													<div className='LearnMoreButton '>
														<CtaButton
															title={
																resourceItem.resourceLinkName
															}
															class='cta link-cta learnMoreBtn'
															href={
																resourceItem.resourceLink
															}
															newPage={true}
															popUp={true}
														/>
													</div>
												</div>
											</Grid>
										</Grid>
									</div>
								)
							)}
						</section>
					</div>
				</div>
			</Layout>
		);
	}
};

export default OnDemandVideo;
